import { InjectionToken } from '@angular/core';
import { Phase } from '@pixels/universal/model/phase-model';

export const PHASE_TOKEN = new InjectionToken<Phase>('PHASE_TOKEN', {
  providedIn: 'root',
  factory: (): Phase => Phase.local
});

export const VAPID_KEY_TOKEN = new InjectionToken<string>('VAPID_KEY_TOKEN', {
  providedIn: 'root',
  factory: (): string => ''
});
