export enum Lang {
  KO = 'ko',
  EN = 'en',
  JA = 'ja',
  CN = 'zh-cht'
}

export const LANGUAGE_CODES: Lang[] = [
  Lang.KO,
];

export const LANGUAGE_CODES_FOR_SCREENSHOTS: string[] = [
  'af',
  'am',
  'ar',
  'az',
  'be',
  'bg',
  'bn',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'eu',
  'fa',
  'fi',
  'fil',
  'fr',
  'gl',
  'gu',
  'hi',
  'hr',
  'hu',
  'hy',
  'id',
  'is',
  'it',
  'iw',
  'ja',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'ky',
  'lo',
  'lt',
  'lv',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms',
  'my',
  'ne',
  'nl',
  'no',
  'pa',
  'pl',
  'pt',
  'ro',
  'ru',
  'si',
  'sk',
  'sl',
  'sq',
  'sr',
  'sv',
  'sw',
  'ta',
  'te',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
  'zh-CN',
  'zh-TW',
  'zh',
  'zu'
];

export const LanguageCodesLabelValues = [
  { "label": "Afrikaans", "value": "af" },
  { "label": "አማርኛ", "value": "am" },
  { "label": "العربية", "value": "ar" },
  { "label": "Azərbaycan dili", "value": "az" },
  { "label": "Беларуская", "value": "be" },
  { "label": "Български", "value": "bg" },
  { "label": "বাংলা", "value": "bn" },
  { "label": "Català", "value": "ca" },
  { "label": "Čeština", "value": "cs" },
  { "label": "Dansk", "value": "da" },
  { "label": "Deutsch", "value": "de" },
  { "label": "Ελληνικά", "value": "el" },
  { "label": "English", "value": "en" },
  { "label": "Español", "value": "es" },
  { "label": "Eesti", "value": "et" },
  { "label": "Euskara", "value": "eu" },
  { "label": "فارسی", "value": "fa" },
  { "label": "Suomi", "value": "fi" },
  { "label": "Filipino", "value": "fil" },
  { "label": "Français", "value": "fr" },
  { "label": "Galego", "value": "gl" },
  { "label": "ગુજરાતી", "value": "gu" },
  { "label": "हिन्दी", "value": "hi" },
  { "label": "Hrvatski", "value": "hr" },
  { "label": "Magyar", "value": "hu" },
  { "label": "Հայերեն", "value": "hy" },
  { "label": "Bahasa Indonesia", "value": "id" },
  { "label": "Íslenska", "value": "is" },
  { "label": "Italiano", "value": "it" },
  { "label": "עברית", "value": "iw" },
  { "label": "日本語", "value": "ja" },
  { "label": "ქართული", "value": "ka" },
  { "label": "Қазақ тілі", "value": "kk" },
  { "label": "ខ្មែរ", "value": "km" },
  { "label": "ಕನ್ನಡ", "value": "kn" },
  { "label": "한국어", "value": "ko" },
  { "label": "Кыргызча", "value": "ky" },
  { "label": "ລາວ", "value": "lo" },
  { "label": "Lietuvių", "value": "lt" },
  { "label": "Latviešu", "value": "lv" },
  { "label": "Македонски", "value": "mk" },
  { "label": "മലയാളം", "value": "ml" },
  { "label": "Монгол", "value": "mn" },
  { "label": "मराठी", "value": "mr" },
  { "label": "Bahasa Melayu", "value": "ms" },
  { "label": "မြန်မာ", "value": "my" },
  { "label": "नेपाली", "value": "ne" },
  { "label": "Nederlands", "value": "nl" },
  { "label": "Norsk", "value": "no" },
  { "label": "ਪੰਜਾਬੀ", "value": "pa" },
  { "label": "Polski", "value": "pl" },
  { "label": "Português", "value": "pt" },
  { "label": "Română", "value": "ro" },
  { "label": "Русский", "value": "ru" },
  { "label": "සිංහල", "value": "si" },
  { "label": "Slovenčina", "value": "sk" },
  { "label": "Slovenščina", "value": "sl" },
  { "label": "Shqip", "value": "sq" },
  { "label": "Српски", "value": "sr" },
  { "label": "Svenska", "value": "sv" },
  { "label": "Kiswahili", "value": "sw" },
  { "label": "தமிழ்", "value": "ta" },
  { "label": "తెలుగు", "value": "te" },
  { "label": "ไทย", "value": "th" },
  { "label": "Türkçe", "value": "tr" },
  { "label": "Українська", "value": "uk" },
  { "label": "اردو", "value": "ur" },
  { "label": "Tiếng Việt", "value": "vi" },
  { "label": "简体中文", "value": "zh-CN" },
  { "label": "繁體中文", "value": "zh-TW" },
  { "label": "中文", "value": "zh" },
  { "label": "isiZulu", "value": "zu" }
];
